export default function getLastNameAndFirstName (name = '') {
  const hyphenated = [
    '欧阳', '太史', '端木', '上官', '司马', '东方', '独孤', '南宫', '万俟', '闻人', '夏侯', '诸葛', '尉迟', '公羊', '赫连', '澹台', '皇甫',
    '宗政', '濮阳', '公冶', '太叔', '申屠', '公孙', '慕容', '仲孙', '钟离', '长孙', '宇文', '城池', '司徒', '鲜于', '司空', '汝嫣', '闾丘', '子车', '亓官',
    '司寇', '巫马', '公西', '颛孙', '壤驷', '公良', '漆雕', '乐正', '宰父', '谷梁', '拓跋', '夹谷', '轩辕', '令狐', '段干', '百里', '呼延', '东郭', '南门',
    '羊舌', '微生', '公户', '公玉', '公仪', '梁丘', '公仲', '公上', '公门', '公山', '公坚', '左丘', '公伯', '西门', '公祖', '第五', '公乘', '贯丘', '公皙',
    '南荣', '东里', '东宫', '仲长', '子书', '子桑', '即墨', '达奚', '褚师'
  ]
  let lastName = ''
  let firstName = ''
  // 先判断是否全为英文字母 - 若全为英文字母，姓为2个字母。若中英参和或全为中文 - 验证是否存在两名姓， 存在则姓2个字。
  if (name.search(/[A-Za-z]/) !== -1) { // 中文姓名全为英文
    lastName = lastName.substr(0, 2)
    firstName = firstName.substr(2)
  } else { // 验证是否存在    hyphenated数组中
    // eslint-disable-next-line no-lonely-if
    if (name.length > 2) {
      const preTwoWords = name.substr(0, 2)
      if (hyphenated.includes(preTwoWords)) {
        lastName = preTwoWords
        firstName = name.substr(2)
      } else {
        lastName = name.substr(0, 1)
        firstName = name.substr(1)
      }
    } else {
      lastName = name.substr(0, 1)
      firstName = name.substr(1)
    }
  }

  return {
    lastName,
    firstName
  }
}
